import Web_development_certificate_page from "../assets/certificates/Web_development_certificate_page.jpg"
import php_developer_certificate_page from "../assets/certificates/php_develope_certificate_page.jpg"


export const certificatesData = [
    {
        img:Web_development_certificate_page,
        title:"Web Development",
        link:"https://drive.google.com/file/d/1f4JPD3MFmvNSkxX-b4ZvhT-ap24mtcxn/view?usp=sharing",
        id:1,
    },
    {
        
        img:php_developer_certificate_page,
        title:"Core PHP",
        link:"https://drive.google.com/file/d/1NlOqiONAL4a5gfcvnxl-nRTRQQ0XxalA/view?usp=sharing",
        id:2,
    },
    
   
   
]