import React from 'react';
import { SkillsData } from '../../data/skillsData';
import SingleSkill from './SingleSkill';
const Skills = () => {
  return (
    <div name='skills' className='w-full h-auto min-h-screen bg-[#0a192f] text-gray-300'>
      {/* Container */}
      <div className='max-w-[1000px]  mx-auto p-4 flex flex-col justify-center w-full h-full'>
          <div>
              <p className='text-4xl font-bold inline border-b-4 border-pink-700 '>Skills</p>
              <p className='py-4'>// These are the technologies I've worked with</p>
          </div>

          <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>

            {SkillsData.map((item)=>(

<SingleSkill key={item.id} Image={item.img} Title={item.title}/>
            ))}

              
          </div>
      </div>
    </div>
  );
};

export default Skills;
